<template>
  <h2 class="text-2xl pt-10">24h Rennen News-Bereich</h2>
  <p v-if="loading">lädt...</p>
  <div v-else class="article-container">
    <swiper :options="swiperOption" class="article-swiper">
      <swiper-slide v-for="(post,index) in posts" :key="index" style="max-height:448px;">
        <article class="article">
          <p class="article--date"> {{new Intl.DateTimeFormat("de-DE", {day:"2-digit",month:'2-digit',year:"numeric"}).format(new Date(post.date)) }}</p>
          <img :src="imgData[index]" width="250">
          <div class="article__textBox">
            <a :href="post.link" target="blank"><span class="article__textBox--title">{{post.title.rendered}}</span></a>
            <p v-if="article_preview" class="article__textBox--text">{{ post.excerpt.rendered.replace(regex, '') }}</p>
          </div>
        </article>
      </swiper-slide>
    </swiper>
  </div>
  <p><small>News von <a href="https://24h-rennen.de">24h-rennen.de</a></small></p>
</template>

<script>

/* PoC */

import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  name: 'news_spinner',
  components: {
    swiper,
    swiperSlide
  },
  data () {
    return {
      regex: /(<[^>]*>)|(\[&hellip;])/gm,
      posts: '',
      imgData: [],
      postsURL: this.url + '/wp-json/wp/v2/posts',
      imgURL: this.url + '/wp-json/wp/v2/media/',
      loading: true,
      swiperOption: {
        slidesPerView: 5,
        spaceBetween: 40,
        loop: true,
        breakpoints: {
          1084: {
            slidesPerView: 4,
            spaceBetween: 20
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10
          }
        }
      }
    }
  },
  props: {
    url: { type: String, required: true },
    article_preview: { type: Boolean, required: false, default: true }
  },
  mounted () {
    this.getPosts()
  },
  methods: {
    getPosts () {
      if (this.postsURL !== undefined && this.imgURL !== undefined) {
        fetch(this.postsURL)
          .then(res => res.json())
          .then(postData => {
            this.posts = postData
            this.loading = false
            for (const [index] of postData.entries()) {
              const featuredMedia = this.posts[index].featured_media
              const postId = this.posts[index].id
              if (featuredMedia && featuredMedia !== '' && postId && postId !== '') {
                fetch(this.imgURL + featuredMedia)
                  .then(res => res.json())
                  .then(imgData => {
                    this.imgData[index] = imgData.media_details.sizes.medium_large.source_url
                  })
                  .catch(err => {
                    throw err
                  })
              }
            }
          })
          .catch(err => {
            throw err
          })
      }
    }
  }
}
</script>
<style lang="scss">
.article-container {
    display: block;
    background-color: #121824;
    padding: 1rem 0;

    .article-swiper {
        width: 95%;
    }
}

.article {
    background: white;
    width: 100%;
    min-height: 290px;
    height: 100%;

    &--date {
        position: absolute;
        background: white;
        padding: 0.25rem;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 11rem;
        font-weight: 600;
    }

    img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        margin: 0 auto;
        object-position: bottom;
    }

    .article__textBox {
        color: #333333;    width: 90%;
        margin: 10px auto;
        min-height: 3rem;
        justify-content: center;
        text-align: center;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;

        a {
            text-decoration: none;
        }

        &--title{
            color: #121824;
            text-decoration: none;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 1.1rem;
        }

        &--text{
            font-family: 'Roboto', sans-serif;
            font-size: 0.8rem;
            text-align: left;
            margin-top: 20px;
            padding-bottom: 20px;
        }
    }
}
</style>
