
import { defineComponent } from 'vue'
import HelloWorld from './components/HelloWorld.vue'
import NewsComponent from './components/News.vue'

export default defineComponent({
  name: 'App',
  components: {
    HelloWorld,
    NewsComponent
  },
  data () {
    return {
      ADAC24h: process.env.VUE_APP_ROOT_API_24h,
      VLN: process.env.VUE_APP_ROOT_API_VLN
    }
  }
})
