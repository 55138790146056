import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HelloWorld = _resolveComponent("HelloWorld")!
  const _component_news_component = _resolveComponent("news-component")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HelloWorld, { msg: "Welcome to Your Vue.js + TypeScript App" }),
    _createVNode(_component_news_component, {
      url: _ctx.ADAC24h,
      article_preview: false
    }, null, 8, ["url"])
  ], 64))
}